<template>
	<div style="min-height: calc(100vh - 125px)">
		<!--begin::Dashboard-->
		<!-- <p class="text-center my-10" style="font-size: 24px; color: #f57c00; font-weight: 600">
			Welcome to Genic Assets
		</p> -->
		<Dashboard></Dashboard>
		<!--end::Dashboard-->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Dashboard from "@/view/pages/dashboard/Dashboard";
export default {
	name: "dashboard",
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
	},
	components: {
		Dashboard,
	},
};
</script>
